<template>
    <div>
        <el-dialog title="关联作业票" :visible.sync="ticketBox" width="90%" top="10vh" @close="closeDialog">
            <grid-layout ref="gridLayOut" :table-options="option" :table-data="tableData" :table-loading="loading"
                :data-total="page.total" :grid-row-btn="[]" :page="page" @page-current-change="onLoad"
                @page-size-change="onLoad">
                <template #customBtn="{row}">
                    <el-button style="margin: 0 3px" type="text" size="small" @click="selectRow(row)">选择</el-button>
                </template>
            </grid-layout>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">{{
            $t("cip.cmn.btn.celBtn")
                    }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";
import { ticketInventoryList } from "@/api/workTicket";
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            ticketBox: true,
            loading: true,
            page: {
                pageSize: 20,
                currentPage: 1,
                total: 0
            },
            tableData: [],
        };
    },

    components: { GridLayout },

    computed: {
        ...mapGetters(['userInfo']),
        option() {
            return {
                selection: false,
                column: [
                    {
                        label: '作业票编号',
                        prop: "code",
                        align: "left",
                        overHidden: true,
                    },
                    {
                        label: '作业票类型',
                        type: "select",
                        align: "center",
                        dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=work_permit_type",
                        props: {
                            label: "dictValue",
                            value: "dictKey"
                        },
                        prop: "type",
                        overHidden: true,
                    },
                    {
                        label: '作业内容',
                        prop: "workContent",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: '作业实施时间',
                        prop: "workExecutionTime",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: '申请人',
                        prop: "createUserName",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: '作业负责人',
                        prop: "workSupervisor",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: '申请时间',
                        prop: "createTime",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: '状态',
                        prop: "businessStatus",
                        dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=wpt_status",
                        props: {
                            label: "dictValue",
                            value: "dictKey"
                        },
                        align: "center",
                        overHidden: true,
                    },
                ]
            }
        }
    },

    mounted() {
        this.onLoad(this.page)
     },

    methods: {
        onLoad(page, params = {}) {
            let that = this
            that.page = page;
            that.loading = true;
            ticketInventoryList(page.currentPage, page.pageSize, Object.assign(params, { organizationId: that.userInfo.dept_id })).then(res => {
                const data = res.data.data;
                that.page.total = data.total;
                that.$nextTick(() => {
                    that.$refs.gridLayOut.page.total = data.total;
                })
                that.tableData = data.records;
                that.loading = false;
            });
        },
        closeDialog(){
            this.$emit('selectWorkTicket','')
            this.ticketBox = false
        },
        selectRow(row){
            this.$emit('selectWorkTicket',row)
            this.ticketBox = false
        }
    },
}
</script>

<style scoped lang='scss'></style>